import React from "react";

export default function CasinoCard({ data, imageUrlCheck = false }) {
  const getImageUrl = (url) => {
    if (imageUrlCheck && url) {
      return url?.slice(1, url.length) || url;
    }
    return url || "";
  };
  return (
    <a href={data.href || "#"} className="casino_games">
      <div className="shadow rounded position-relative">
        <img
          src={getImageUrl(data?.cover?.src || data?.imgUrl)}
          alt={data?.cover?.alt || ""}
        />
      </div>
    </a>
  );
}

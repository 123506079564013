import React from "react";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";
import { useSelector } from "react-redux";
import BackIcon from "../../../../assets/svg/BackIcon";
import { casinoTabs } from "../../../../lib/data";
import { Col } from "react-bootstrap";
import CasinoCard from "../../../components/CasinoCard";

const Slots = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main mt-4">
        <div className=" container">
          <div className="d-flex justify-content-between HeadingSection">
            <h2 className="sectionTitle mb-0">Jilli</h2>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>

          <div className="slotsPageSection">
            <div className="JilliGamesSection">
              {Array.isArray(Object.values(casinoTabs.jilliGames)) &&
                Object.values(casinoTabs.jilliGames).map((game, idx) => (
                  <Col xs={4} lg={3} className="Live_games_col">
                    <div className="games-all">
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    </div>
                  </Col>
                ))}
            </div>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Slots;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../../../assets/svg/BackIcon";

import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

import VivoImg from "../../../../assets/images/providers/Vivo.gif";
import PragmaticImg from "../../../../assets/images/providers/Pragmatic.gif";
import SupernowaImg from "../../../../assets/images/providers/Supernowa.jpg";
import WorldCasinoImg from "../../../../assets/images/providers/Worldcasino.jpg";
import EzugiImg from "../../../../assets/images/providers/EZUGInew.jpg";
import EvolutionImg from "../../../../assets/images/providers/Evolutionnew.jpg";
import JilliImg from "../../../../assets/images/providers/jili.jpg";

import { APP_CONST } from "../../../../config/const";
import ModalWrapper from "../../../../containers/ModalWrapper";

const ProviderPage = () => {
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(!show1);
  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  const htmlElement = document.documentElement;
  useEffect(() => {
    if (
      show1 ||
      showAuthModals.login ||
      showAuthModals.mobileNumber ||
      showAuthModals.verifyNumber ||
      showAuthModals.register ||
      showAuthModals.forgotpassword ||
      showAuthModals.resetpassword
    ) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
  }, [
    show1,
    showAuthModals.login,
    showAuthModals.mobileNumber,
    showAuthModals.verifyNumber,
    showAuthModals.register,
    showAuthModals.forgotpassword,
    showAuthModals.resetpassword,
  ]);
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <Header />
      </div>
      <main className="main">
        <div className=" container">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="sectionTitle">Providers</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="providersection">
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  <img src={EzugiImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/evolution">
                  <img src={EvolutionImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/vivo">
                  <img src={VivoImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/supernowa">
                  <img src={SupernowaImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/worldcasino">
                  <img src={WorldCasinoImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/pragmaticplay">
                  <img src={PragmaticImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  <img src={JilliImg} alt="EzugiImg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <div>
        <BeforeLoginFooter />
      </div>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setDefaultModal}
        />
      )}
    </div>
  );
};

export default ProviderPage;

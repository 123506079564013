export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;
export const casinoTabs = {
  popular: {
    slide1: {
      VivoAndarBahar: {
        redirectUrl: "/casino/vivo-andar-bahar",
        code: "0",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/popular/VivoAndarBahar.jpg",
        alt: "vivo andar bahar",
        name: "Vivo Andar Bahar",
        providerName: "Vivo",
      },
      VivoTeenPatti: {
        redirectUrl: "/casino/vivo-teen-patti",
        code: "0",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/popular/VivoTeenPatti.jpg",
        alt: "vivo teen patti",
        name: "Vivo Teen Patti",
        providerName: "Vivo",
      },
    },
    name: "Popular",
  },
  roulettetab: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
      // AutoRoulettePargmatic: {
      //   redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
      //   code: "vs20stickysymbol",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
      //   alt: "",
      //   name: "Auto Roulette Pragmatic",
      //   providerName: "Pragmatic Play",
      // },
      // Roulette1Azure: {
      //   redirectUrl: "/casino/ezugi/Roulette1Azure",
      //   code: "227",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Roulette1Azure.jpg",
      //   alt: "",
      //   name: "Roulette Azure",
      //   providerName: "Pragmatic Play",
      // },
      // RouletteLobby: {
      //   redirectUrl: "/casino/ezugi/RouletteLobby",
      //   code: "102",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/roulletewheel.jpg",
      //   alt: "",
      //   name: "Roulette Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    // slide3: {
    //   AutoRoulettePargmatic: {
    //     redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
    //     code: "225",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
    //     alt: "",
    //     name: "Auto Roulette Pragmatic",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette1Azure: {
    //     redirectUrl: "/casino/ezugi/Roulette1Azure",
    //     code: "227",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette1Azure.jpg",
    //     alt: "",
    //     name: "Roulette Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteLobby: {
    //     redirectUrl: "/casino/ezugi/RouletteLobby",
    //     code: "102",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulletewheel.jpg",
    //     alt: "",
    //     name: "Roulette Lobby",
    //     providerName: "Pragmatic Play",
    //   },
    //   PragmaticTurkishRoulette: {
    //     redirectUrl: "/casino/ezugi/PragmaticTurkishRoulette",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/pragturkish.jpg",
    //     alt: "",
    //     name: "Turkish Roulette ",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulettegerman: {
    //     redirectUrl: "/casino/ezugi/roulettegerman",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulettegerman.jpg",
    //     alt: "",
    //     name: "Roulette German",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteRussian: {
    //     redirectUrl: "/casino/ezugi/RouletteRussian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette-Russian.jpg",
    //     alt: "",
    //     name: "Roulette Russian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette3Macao: {
    //     redirectUrl: "/casino/ezugi/Roulette3Macao",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette3Macao.jpg",
    //     alt: "",
    //     name: "Roulette Macao",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette9TheClub: {
    //     redirectUrl: "/casino/ezugi/Roulette9TheClub",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette9TheClub.jpg",
    //     alt: "",
    //     name: "Roulette The Club",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulette7italian: {
    //     redirectUrl: "/casino/ezugi/roulette7italian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulette7italian.jpg",
    //     alt: "",
    //     name: "Roulette 7 Italian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette10Ruby: {
    //     redirectUrl: "/casino/ezugi/Roulette10Ruby",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette10Ruby.jpg",
    //     alt: "",
    //     name: "Roulette 10 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Roulette",
  },
  baccarattab: {
    slide1: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide2: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    // slide3: {
    //   NoCommSpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/NoCommSpeedBaccarat1",
    //     code: "436",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/NoCommSpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "No Commission Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   SpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/SpeedBaccarat1",
    //     code: "566",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/SpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "Speed Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   // speedbaccarat5: {
    //   //   redirectUrl: "/casino/ezugi/speedbaccarat5",
    //   //   code: "570",
    //   //   casino: "ezugi",
    //   //   provider: "",
    //   //   homeUrl: pageUrlGenerator("/Casino"),
    //   //   imgUrl: "./images/roulette/speedbaccarat5.jpg",
    //   //   alt: "",
    //   //   name: "Speed Baccarat 5",
    //   //   providerName: "Pragmatic Play",
    //   // },
    // },
    name: "Baccarat",
  },
  Blackjacktab: {
    slide1: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      // TurkceBlackjack: {
      //   redirectUrl: "/casino/ezugi/TurkceBlackjack",
      //   code: "501",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
      //   alt: "",
      //   name: "Turkce Blackjack 3",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      // FreebetBlackjack: {
      //   redirectUrl: "/casino/ezugi/FreebetBlackjack",
      //   code: "1000066",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
      //   alt: "",
      //   name: "Freebet Blackjack",
      //   providerName: "Evolution",
      // },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack1: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack1",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide2: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      // ClubBlackjack: {
      //   redirectUrl: "/casino/ezugi/ClubBlackjack",
      //   code: "502",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Club-Blackjack.jpg",
      //   alt: "",
      //   name: "Club Blackjack",
      //   providerName: "Ezugi",
      // },
      // Rumbablackjacks2: {
      //   redirectUrl: "/casino/ezugi/Rumbablackjacks2",
      //   code: "412",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 2",
      //   providerName: "Ezugi",
      // },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      // AllStarsBlackjackGold1: {
      //   redirectUrl: "/casino/ezugi/AllStarsBlackjackGold1",
      //   code: "504",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
      //   alt: "",
      //   name: "All Stars Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },

    // slide3: {
    //   Blackjack17Azure: {
    //     redirectUrl: "/casino/ezugi/Blackjack17Azure",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack17Azure.jpg",
    //     alt: "",
    //     name: "Blackjack 17 Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   GoldBlackjack: {
    //     redirectUrl: "/casino/ezugi/GoldBlackjack",
    //     code: "1",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack02.jpg",
    //     alt: "",
    //     name: "Blackjack Gold 5",
    //     providerName: "Ezugi",
    //   },
    //   Blackjack39Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack39Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack39Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 39 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack42Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack42Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack42Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 42 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   UnlimitedBlackjack: {
    //     redirectUrl: "/casino/ezugi/UnlimitedBlackjack",
    //     code: "51",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Unlimited-Blackjack.jpg",
    //     alt: "",
    //     name: "Unlimited Blackjack",
    //     providerName: "Ezugi",
    //   },

    //   BlackjackVIPO: {
    //     redirectUrl: "/casino/ezugi/BlackjackVIPO",
    //     code: "1000058",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack-VIP-O.jpg",
    //     alt: "",
    //     name: "Blackjack VIP O",
    //     providerName: "Evolution",
    //   },
    //   Blackjack11: {
    //     redirectUrl: "/casino/ezugi/Blackjack11",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack11.jpg",
    //     alt: "",
    //     name: "Blackjack 11",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack14: {
    //     redirectUrl: "/casino/ezugi/Blackjack14",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack14.jpg",
    //     alt: "",
    //     name: "Blackjack 14",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack16: {
    //     redirectUrl: "/casino/ezugi/Blackjack16",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack16.jpg",
    //     alt: "",
    //     name: "Blackjack 16",
    //     providerName: "Pragmatic Play",
    //   },
    //   OneBlackjack: {
    //     redirectUrl: "/casino/ezugi/OneBlackjack",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/OneBlackjack.jpg",
    //     alt: "",
    //     name: "One Blackjack",
    //     providerName: "Pragmatic Play",
    //   },
    //   ONEBlackjack2: {
    //     redirectUrl: "/casino/ezugi/ONEBlackjack2",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/ONEBlackjack2.jpg",
    //     alt: "",
    //     name: "One Blackjack 2",
    //     providerName: "Pragmatic Play",
    //   },
    //   SpeedBlackjack3Ruby: {
    //     redirectUrl: "/casino/ezugi/SpeedBlackjack3Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/SpeedBlackjack3Ruby.jpg",
    //     alt: "",
    //     name: "Speed Blackjack 3 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Blackjack",
  },
  pokertab: {
    slide1: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti1: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti1",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    name: "Poker",
  },
  spribe: {
    slide1: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/aviator.gif",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },

    name: "Spribe",
  },
  Supernowa: {
    slide1: {
      // playerTP: {
      //   redirectUrl: "/casino/supernowa/playerTP",
      //   code: "D3TP",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/3PlayerTeenPatti.jpg",
      //   alt: "",
      //   name: "3 Player TeenPatti",
      //   providerName: "Supernowa",
      // },
      Cards32SN: {
        redirectUrl: "/casino/supernowa/Cards32SN",
        code: "C32",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/32Card.jpg",
        alt: "",
        name: "32 Cards",
        providerName: "Supernowa",
      },
      AkbarRomeoWalter: {
        redirectUrl: "/casino/supernowa/AkbarRomeoWalter",
        code: "ARW",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/AkbarRomeoWalter.jpg",
        alt: "",
        name: "Akbar Romeo Walter",
        providerName: "Supernowa",
      },
      AndarBaharSN: {
        redirectUrl: "/casino/supernowa/AndarBaharSN",
        code: "ABC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/AndarBahar.jpg",
        alt: "",
        name: "Andar Bahar",
        providerName: "Supernowa",
      },
      BaccaratSN: {
        redirectUrl: "/casino/supernowa/BaccaratSN",
        code: "BAC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Baccarat.jpg",
        alt: "",
        name: "Baccarat",
        providerName: "Supernowa",
      },
      // CasinoQueen: {
      //   redirectUrl: "/casino/supernowa/CasinoQueen",
      //   code: "CQ",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/CasinoQueen.jpg",
      //   alt: "",
      //   name: "Casino Queen",
      //   providerName: "Supernowa",
      // },
      ClassicAndarBaharSN: {
        redirectUrl: "/casino/supernowa/ClassicAndarBaharSN",
        code: "AB",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/ClassicAndarBahar.jpg",
        alt: "",
        name: "Classic Andar Bahar",
        providerName: "Supernowa",
      },
      DragonTigerSN: {
        redirectUrl: "/casino/supernowa/DragonTigerSN",
        code: "DT7M",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/DragonTiger.jpg",
        alt: "",
        name: "Dragon Tiger",
        providerName: "Supernowa",
      },
      GoaAndarBaharSN: {
        redirectUrl: "/casino/supernowa/GoaAndarBaharSN",
        code: "AB2",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/GoasAndarBahar.jpg",
        alt: "",
        name: "Goa's Andar Bahar",
        providerName: "Supernowa",
      },
      // KingRace: {
      //   redirectUrl: "/casino/supernowa/KingRace",
      //   code: "CR",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/KingRace.jpg",
      //   alt: "",
      //   name: "King Race",
      //   providerName: "Supernowa",
      // },
      Lucky7SN: {
        redirectUrl: "/casino/supernowa/Lucky7SN",
        code: "UD7",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Lucky7.jpg",
        alt: "",
        name: "Lucky 7",
        providerName: "Supernowa",
      },
      // PokerSN: {
      //   redirectUrl: "/casino/supernowa/PokerSN",
      //   code: "PK",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/Poker.jpg",
      //   alt: "",
      //   name: "Poker",
      //   providerName: "Supernowa",
      // },
    },
    slide2: {
      RouletteSN: {
        redirectUrl: "/casino/supernowa/RouletteSN",
        code: "RT",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Roulette.jpg",
        alt: "",
        name: "Roulette",
        providerName: "Supernowa",
      },
      TeenPattiSN: {
        redirectUrl: "/casino/supernowa/TeenPattiSN",
        code: "TP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Teen-Patti.jpg",
        alt: "",
        name: "Teen Patti",
        providerName: "Supernowa",
      },
      TeenPatti2020SN: {
        redirectUrl: "/casino/supernowa/TeenPatti2020SN",
        code: "TP20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/TeenPatti2020.jpg",
        alt: "",
        name: "Teen Patti 2020",
        providerName: "Supernowa",
      },
      TeenPattiFaceOffSN: {
        redirectUrl: "/casino/supernowa/TeenPattiFaceOffSN",
        code: "TPFO",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/TeenPattiFaceOff.jpg",
        alt: "",
        name: "Teen Patti Face Off",
        providerName: "Supernowa",
      },
      // WorliMatka: {
      //   redirectUrl: "/casino/supernowa/WorliMatka",
      //   code: "WM",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/WorliMatka.jpg",
      //   alt: "",
      //   name: "Worli Matka",
      //   providerName: "Supernowa",
      // },
      // RNG3CardsJudgement: {
      //   redirectUrl: "/casino/supernowa/RNG3CardsJudgement",
      //   code: "V3CJ",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNG3CardsJudgement.jpg",
      //   alt: "",
      //   name: "RNG 3 Cards Judgement",
      //   providerName: "Supernowa",
      // },
      RNG3PlayerTeenPatti: {
        redirectUrl: "/casino/supernowa/RNG3PlayerTeenPatti",
        code: "V3TP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG3PlayerTeenPatti.jpg",
        alt: "",
        name: "RNG 3 Player Teen Patti",
        providerName: "Supernowa",
      },
      RNG32Cards: {
        redirectUrl: "/casino/supernowa/RNG32Cards",
        code: "VC32",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG32Cards.jpg",
        alt: "",
        name: "RNG 32 Cards",
        providerName: "Supernowa",
      },
      RNGAkbarRomeoWalter: {
        redirectUrl: "/casino/supernowa/RNGAkbarRomeoWalter",
        code: "VARW",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGAkbarRomeoWalter.jpg",
        alt: "",
        name: "RNG Akbar Romeo Walter",
        providerName: "Supernowa",
      },
      // RNGAndarBahar2020: {
      //   redirectUrl: "/casino/supernowa/RNGAndarBahar2020",
      //   code: "VAB20",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGAndarBahar2020.jpg",
      //   alt: "",
      //   name: "RNG Andar Bahar 2020",
      //   providerName: "Supernowa",
      // },
      RNGBaccarat: {
        redirectUrl: "/casino/supernowa/RNGBaccarat",
        code: "VBAC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGBaccarat.jpg",
        alt: "",
        name: "RNG Baccarat",
        providerName: "Supernowa",
      },
      // RNGBaccarat2020: {
      //   redirectUrl: "/casino/supernowa/RNGBaccarat2020",
      //   code: "VBAC20",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGBaccarat2020.jpg",
      //   alt: "",
      //   name: "RNG Baccarat 2020",
      //   providerName: "Supernowa",
      // },
    },
    slide3: {
      RNGCasinoQueen: {
        redirectUrl: "/casino/supernowa/RNGCasinoQueen",
        code: "RCQ",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
        alt: "",
        name: "RNG Casino Queen",
        providerName: "Supernowa",
      },
      RNGCasinoQueen2020: {
        redirectUrl: "/casino/supernowa/RNGCasinoQueen2020",
        code: "RCQ20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
        alt: "",
        name: "RNG Casino Queen 2020",
        providerName: "Supernowa",
      },
      RNGCricket2020: {
        redirectUrl: "/casino/supernowa/RNGCricket2020",
        code: "RCKT20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCricket2020.jpg",
        alt: "",
        name: "RNG Cricket 2020",
        providerName: "Supernowa",
      },
      RNGDragonTiger: {
        redirectUrl: "/casino/supernowa/RNGDragonTiger",
        code: "VDT",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
        alt: "",
        name: "RNG Dragon Tiger",
        providerName: "Supernowa",
      },
      RNGDragonTiger2020: {
        redirectUrl: "/casino/supernowa/RNGDragonTiger2020",
        code: "VDT20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
        alt: "",
        name: "RNG Dragon Tiger 2020",
        providerName: "Supernowa",
      },
      RNGJoker: {
        redirectUrl: "/casino/supernowa/RNGJoker",
        code: "VJKR",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGJoker.jpg",
        alt: "",
        name: "RNG Joker",
        providerName: "Supernowa",
      },
      // RNGKabutarFuddi: {
      //   redirectUrl: "/casino/supernowa/RNGKabutarFuddi",
      //   code: "VKF",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGKabutarFuddi.jpg",
      //   alt: "",
      //   name: "RNG Kabutar Fuddi",
      //   providerName: "Supernowa",
      // },
      RNGKingRace: {
        redirectUrl: "/casino/supernowa/RNGKingRace",
        code: "VCR",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGKingRace.jpg",
        alt: "",
        name: "RNG King Race",
        providerName: "Supernowa",
      },
      RNGLucky7: {
        redirectUrl: "/casino/supernowa/RNGLucky7",
        code: "VUD7",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGLucky7.jpg",
        alt: "",
        name: "RNG Lucky 7",
        providerName: "Supernowa",
      },
      // RNGPoker: {
      //   redirectUrl: "/casino/supernowa/RNGPoker",
      //   code: "VPK",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGPoker.jpg",
      //   alt: "",
      //   name: "RNG Poker",
      //   providerName: "Supernowa",
      // },
      // RNGPoker2020: {
      //   redirectUrl: "/casino/supernowa/RNGPoker2020",
      //   code: "VPK20",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGPoker2020.jpg",
      //   alt: "",
      //   name: "RNG Poker 2020",
      //   providerName: "Supernowa",
      // },
      RNGTeenPatti: {
        redirectUrl: "/casino/supernowa/RNGTeenPatti",
        code: "VTP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
        alt: "",
        name: "RNG Teen Patti",
        providerName: "Supernowa",
      },
      RNGTeenPatti2020: {
        redirectUrl: "/casino/supernowa/RNGTeenPatti2020",
        code: "VTP20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
        alt: "",
        name: "RNG Teen Patti 2020",
        providerName: "Supernowa",
      },
      RNGWorliMatka: {
        redirectUrl: "/casino/supernowa/RNGWorliMatka",
        code: "VWM",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
        alt: "",
        name: "RNG Worli Matka",
        providerName: "Supernowa",
      },
    },

    name: "Supernowa",
  },
  Vivo: {
    slide1: {
      CoffeeWild: {
        redirectUrl: "/casino/vivo-dragon-tiger",
        imgUrl: "./images/Vivo/DragonTiger.jpg",
        name: "Dragon Tiger",
        providerName: "Vivo",
      },
      EuropeanAutoRoulette: {
        redirectUrl: "/casino/vivo-european-auto-roulette",
        imgUrl: "./images/Vivo/EuropeanAutoRoulette.jpg",
        name: "European Auto Roulette",
        providerName: "Vivo",
      },
      FrenchRoulette: {
        redirectUrl: "/casino/vivo-french-roulette",
        imgUrl: "./images/Vivo/FrenchRoulette.jpg",
        name: "French Roulette",
        providerName: "Vivo",
      },
      GalaxyBaccarat1: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-1",
        imgUrl: "./images/Vivo/GalaxyBaccarat1.jpg",
        name: "Galaxy Baccarat1",
        providerName: "Vivo",
      },
      GalaxyBaccarat2: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-2",
        imgUrl: "./images/Vivo/GalaxyBaccarat2.jpg",
        name: "Galaxy Baccarat 2",
        providerName: "Vivo",
      },
      GalaxyBaccarat3: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-3",
        imgUrl: "./images/Vivo/GalaxyBaccarat3.jpg",
        name: "Galaxy Baccarat 3",
        providerName: "Vivo",
      },
      GalaxyRoulette: {
        redirectUrl: "/casino/vivo-galaxy-roulette",
        imgUrl: "./images/Vivo/GalaxyRoulette.jpg",
        name: "Galaxy Roulette",
        providerName: "Vivo",
      },
      MacauBaccarat: {
        redirectUrl: "/casino/vivo-macau-baccarat",
        imgUrl: "./images/Vivo/MacauBaccarat.jpg",
        name: "Macau Baccarat",
        providerName: "Vivo",
      },
      Oracle360Roulette: {
        redirectUrl: "/casino/vivo-oracle-360-roulette",
        imgUrl: "./images/Vivo/Oracle360Roulette.jpg",
        name: "Oracle 360 Roulette",
        providerName: "Vivo",
      },
      OracleBlazeRoulette: {
        redirectUrl: "/casino/vivo-oracle-blaze-roulette",
        imgUrl: "./images/Vivo/OracleBlazeRoulette.jpg",
        name: "Oracle Blaze Roulette",
        providerName: "Vivo",
      },
      PortomasoRoulette: {
        redirectUrl: "/casino/vivo-portomaso-roulette",
        imgUrl: "./images/Vivo/PortomasoRoulette.jpg",
        name: "Portomaso Roulette",
        providerName: "Vivo",
      },
      TeenPatti: {
        redirectUrl: "/casino/vivo-teen-patti",
        imgUrl: "./images/Vivo/TeenPatti.jpg",
        name: "Teen Patti",
        providerName: "Vivo",
      },
    },
    slide2: {
      VABacarat1: {
        redirectUrl: "/casino/vivo-va-bacarat-1",
        imgUrl: "./images/Vivo/VABacarat1.jpg",
        name: "VA Bacarat 1",
        providerName: "Vivo",
      },
      VABacarat2: {
        redirectUrl: "/casino/vivo-va-bacarat-2",
        imgUrl: "./images/Vivo/VABacarat2.jpg",
        name: "VA Bacarat 2",
        providerName: "Vivo",
      },
      VABacarat3: {
        redirectUrl: "/casino/vivo-va-bacarat-3",
        imgUrl: "./images/Vivo/VABacarat3.jpg",
        name: "VA Bacarat 3",
        providerName: "Vivo",
      },
      VABacarat4: {
        redirectUrl: "/casino/vivo-va-bacarat-4",
        imgUrl: "./images/Vivo/VABacarat4.jpg",
        name: "VA Bacarat 4",
        providerName: "Vivo",
      },
      VABacarat5: {
        redirectUrl: "/casino/vivo-va-bacarat-5",
        imgUrl: "./images/Vivo/VABacarat5.jpg",
        name: "VA Bacarat 5",
        providerName: "Vivo",
      },
    },
    name: "Vivo",
  },
  TvShows: {
    slide1: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      // GonzosTreasureHunt: {
      //   redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
      //   code: "1100022",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
      //   alt: "",
      //   name: "Gonzo's Treasure Hunt",
      //   providerName: "Evolution",
      // },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "TV Games",
  },
  AllGames: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
    },
    slide3: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide4: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    slide5: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      // TurkceBlackjack: {
      //   redirectUrl: "/casino/ezugi/TurkceBlackjack",
      //   code: "501",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
      //   alt: "",
      //   name: "Turkce Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      // FreebetBlackjack: {
      //   redirectUrl: "/casino/ezugi/FreebetBlackjack",
      //   code: "1000066",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
      //   alt: "",
      //   name: "Freebet Blackjack",
      //   providerName: "Evolution",
      // },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide6: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      // ClubBlackjack: {
      //   redirectUrl: "/casino/ezugi/ClubBlackjack",
      //   code: "502",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Club-Blackjack.jpg",
      //   alt: "",
      //   name: "Club Blackjack",
      //   providerName: "Ezugi",
      // },
      RumbaBlackjack2: {
        redirectUrl: "/casino/ezugi/RumbaBlackjack2",
        code: "412",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
        alt: "",
        name: "Rumba Blackjack 2",
        providerName: "Ezugi",
      },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      AllStarsBlackjackGold: {
        redirectUrl: "/casino/ezugi/AllStarsBlackjackGold",
        code: "504",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
        alt: "",
        name: "All Stars Blackjack",
        providerName: "Ezugi",
      },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide7: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti",
        code: "227102",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide8: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/aviator.gif",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },
    slide9: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      // GonzosTreasureHunt: {
      //   redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
      //   code: "1100022",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
      //   alt: "",
      //   name: "Gonzo's Treasure Hunt",
      //   providerName: "Evolution",
      // },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "All Games",
  },

  jilliGames: {
    // RummyJili: {
    //   href: "/casino/aggapjiligaming-rummy",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/RummyGame.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Rummy Game",
    //   name: "Rummy Game",
    // },
    // Up7DownJili: {
    //   href: "/casino/aggapjiligaming-7-up-down",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/7updown.webp",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "7 UP-DOWN",
    //   name: "7 UP-DOWN",
    // },
    Coin3Treasures: {
      href: "/casino/qtechjili-3-coin-treasures",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/3CoinTreasures.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "3 Coin Treasures",
      name: "3 Coin Treasures",
    },
    LuckyPiggy3: {
      href: "/casino/qtechjili-3-lucky-piggy",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/3luckypiggy.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "3 Lucky Piggy",
      name: "3 Lucky Piggy",
    },
    PotDragon3: {
      href: "/casino/qtechjili-3-pot-dragons",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/3PotDragon.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "3 Pot Dragon",
      name: "3 Pot Dragon",
    },
    AgentAce: {
      href: "/casino/qtechjili-agent-ace",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/AgentAce.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Agent Ace",
      name: "Agent Ace",
    },
    AliBaba: {
      href: "/casino/qtechjili-ali-baba",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/AliBaba.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Ali Baba",
      name: "Ali Baba",
    },
    Arenafighter: {
      href: "/casino/qtechjili-arena-fighter",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Arenafighter.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Arena Fighter",
      name: "Arena Fighter",
    },
    aztecpriestess: {
      href: "/casino/qtechjili-aztec-priestess",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/aztecpriestess.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Aztec Priestess",
      name: "Aztec Priestess",
    },
    // AndarBaharJili: {
    //   href: "/casino/aggapjiligaming-andar-bahar",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/AndarBahar.webp",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Andar Bahar",
    //   name: "Andar Bahar",
    // },
    // AK47Jili: {
    //   href: "/casino/aggapjiligaming-ak47",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/AK47.webp",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "AK47",
    //   name: "AK47",
    // },
    BaoBoonChinJili: {
      href: "/casino/qtechjili-bao-boon-chin",
      code: "",
      casino: "JiLi",
      provider: "ALT_GAMING_GAP",
      imgUrl: "./images/games/jili/Baoboonchin.webp",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Bao boon chin",
      name: "Bao boon chin",
    },
    BanglaBeauty: {
      href: "/casino/qtechjili-bangla-beauty",
      code: "",
      casino: "JiLi",
      provider: "ALT_GAMING_GAP",
      imgUrl: "./images/games/jili/BanglaBeauty.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Bangla Beauty",
      name: "Bangla Beauty",
    },
    // BaoBBombingFishingJili: {
    //   href: "/casino/aggapjiligaming-bombing-fishing",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/Bombingfishing.webp",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Bombing Fishing",
    //   name: "Bombing Fishing",
    // },
    // BombingFishing: {
    //   href: "/casino/aggapjiligaming-bombing-fishing",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/BombingFishing.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Bombing Fishing",
    //   name: "Bombing Fishing",
    // },
    // Boomlegend: {
    //   href: "/casino/aggapjiligaming-boom-legend",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/Boomlegend.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Boom Legend",
    //   name: "Boom Legend",
    // },
    BoneFortune: {
      href: "/casino/qtechjili-bone-fortune",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/BoneFortune.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Bone Fortune",
      name: "Bone Fortune",
    },
    BonusHunter: {
      href: "/casino/qtechjili-bonus-hunter",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/BonusHunter.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Bonus Hunter",
      name: "Bonus Hunter",
    },
    BookofGold: {
      href: "/casino/qtechjili-book-of-gold",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/BookofGold.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Book Of Gold",
      name: "Book Of Gold",
    },
    BoxingKing: {
      href: "/casino/qtechjili-boxing-king",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/BoxingKing.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Boxing King",
      name: "Boxing King",
    },
    BubbleBeauty: {
      href: "/casino/qtechjili-bubble-beauty",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/BubbleBeauty.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Bubble Beauty",
      name: "Bubble Beauty",
    },
    Chinshihuang: {
      href: "/casino/qtechjili-chin-shi-huang",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Chinshihuang.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Chin Shi Huang",
      name: "Chin Shi Huang",
    },
    Crazy777: {
      href: "/casino/qtechjili-crazy777",
      code: "",
      casino: "JiLi",
      provider: "ALT_GAMING_GAP",
      imgUrl: "./images/games/jili/Crazy777.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Crazy777",
      name: "Crazy777",
    },
    ChargeBuffalo: {
      href: "/casino/qtechjili-charge-buffalo",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/ChargeBuffalo.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Charge Buffalo",
      name: "Charge Buffalo",
    },
    ChargeBuffaloAscent: {
      href: "/casino/qtechjili-charge-buffalo-ascent",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/ChargeBuffaloAscent.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Charge Buffalo Ascent",
      name: "Charge Buffalo Ascent",
    },
    CrazyHunter: {
      href: "/casino/qtechjili-crazy-hunter",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/CrazyHunter.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Crazy Hunter",
      name: "Crazy Hunter",
    },
    CricketKing18: {
      href: "/casino/qtechjili-cricket-king-18",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/CricketKing18.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Cricket King 18",
      name: "Cricket King 18",
    },
    CricketSah75: {
      href: "/casino/qtechjili-cricket-sah-75",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/CricketSah75.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Cricket Sah 75",
      name: "Cricket Sah 75",
    },
    Dabanggg: {
      href: "/casino/qtechjili-dabanggg",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Dabanggg.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Dabanggg",
      name: "Dabanggg",
    },
    DevilFire2: {
      href: "/casino/qtechjili-devil-fire-2",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/DevilFire2.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Devil Fire 2",
      name: "Devil Fire 2",
    },
    DiamondParty: {
      href: "/casino/qtechjili-diamond-party",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/DiamondParty.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Diamond Party",
      name: "Diamond Party",
    },
    DragonTreasure: {
      href: "/casino/qtechjili-dragon-treasure",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/DragonTreasure.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Dragon Treasure",
      name: "Dragon Treasure",
    },
    // dinosaurtycoon: {
    //   href: "/casino/aggapjiligaming-dinosaur-tycoon",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/dinosaurtycoon.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Dinosaur Tycoon",
    //   name: "Dinosaur Tycoon",
    // },
    // DragonFortune: {
    //   href: "/casino/aggapjiligaming-dragon-fortune",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/DragonFortune.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Dragon Fortune",
    //   name: "Dragon Fortune",
    // },
    EgyptsGlow: {
      href: "/casino/qtechjili-egypts-glow",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/EgyptsGlow.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Egypt's Glow",
      name: "Egypt's Glow",
    },
    Fengshen: {
      href: "/casino/qtechjili-fengshen",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Fengshen.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Fengshen",
      name: "Fengshen",
    },
    FortuneGems: {
      href: "/casino/qtechjili-fortune-gems",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/FortuneGems.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Fortune Gems",
      name: "Fortune Gems",
    },
    FortuneGems2: {
      href: "/casino/qtechjili-fortune-gems-2",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/FortuneGems2.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Fortune Gems 2",
      name: "Fortune Gems 2",
    },
    FortuneGems3: {
      href: "/casino/qtechjili-fortune-gems-3",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/FortuneGems3.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Fortune Gems 3",
      name: "Fortune Gems 3",
    },
    FortuneMonkey: {
      href: "/casino/qtechjili-fortune-monkey",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/FortuneMonkey.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Fortune Monkey",
      name: "Fortune Monkey",
    },
    Fafafa: {
      href: "/casino/qtechjili-crazy-fafafa",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Fafafa.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Crazy FaFaFa",
      name: "Crazy FaFaFa",
    },
    // FortuneTree: {
    //   href: "/casino/aggapjiligaming-fortune-tree",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/FortuneTree.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Fortune Tree",
    //   name: "Fortune Tree",
    // },
    GemParty: {
      href: "/casino/qtechjili-gem-party",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GemParty.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Gem Party",
      name: "Gem Party",
    },
    GodOfMartial: {
      href: "/casino/qtechjili-god-of-martial",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GodOfMartial.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "God Of Martial",
      name: "God Of Martial",
    },
    GoldenBank: {
      href: "/casino/qtechjili-golden-bank",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GoldenBank.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Golden Bank",
      name: "Golden Bank",
    },
    GoldRush: {
      href: "/casino/qtechjili-gold-rush",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GoldRush.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Golden Rush",
      name: "Golden Rush",
    },
    GoldenEmpire: {
      href: "/casino/qtechjili-golden-empire",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GoldenEmpire.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Golden Empire",
      name: "Golden Empire",
    },
    GoldenJoker: {
      href: "/casino/qtechjili-golden-joker",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GoldenJoker.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Golden Joker",
      name: "Golden Joker",
    },
    GoldenQueen: {
      href: "/casino/qtechjili-golden-queen",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GoldenQueen.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Golden Queen",
      name: "Golden Queen",
    },
    GoldenTemple: {
      href: "/casino/qtechjili-golden-temple",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/GoldenTemple.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Golden Temple",
      name: "Golden Temple",
    },
    HappyTaxi: {
      href: "/casino/qtechjili-happy-taxi",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/HappyTaxi.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Happy Taxi",
      name: "Happy Taxi",
    },
    // HappyFishing: {
    //   href: "/casino/aggapjiligaming-happy-fishing",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/HappyFishing.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Happy Fishing",
    //   name: "Happy Fishing",
    // },
    HawaiiBeauty: {
      href: "/casino/qtechjili-hawaii-beauty",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/HawaiiBeauty.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Hawaii Beauty",
      name: "Hawaii Beauty",
    },
    HotChili: {
      href: "/casino/qtechjili-hot-chilli",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/HotChili.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Hot Chilli",
      name: "Hot Chilli",
    },
    HyperBrust: {
      href: "/casino/qtechjili-hyper-burst",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/HyperBrust.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Hyper Burst",
      name: "Hyper Burst",
    },
    // JackpotFishing: {
    //   href: "/casino/aggapjiligaming-jackpot-fishing",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/JackpotFishing.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Jackpot Fishing",
    //   name: "Jackpot Fishing",
    // },
    JILICAISHEN: {
      href: "/casino/qtechjili-jili-caishen",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/JILICAISHEN.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "JILI CAISHEN",
      name: "JILI CAISHEN",
    },
    JungleKing: {
      href: "/casino/qtechjili-jungle-king",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/JungleKing.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Jungle King",
      name: "Jungle King",
    },
    KingArthur: {
      href: "/casino/qtechjili-king-arthur",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/KingArthur.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "King Arthur",
      name: "King Arthur",
    },
    LegacyofEgypt: {
      href: "/casino/qtechjili-legacy-of-egypt",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/LegacyofEgypt.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Legacy of Egypt",
      name: "Legacy of Egypt",
    },
    LuckyBall: {
      href: "/casino/qtechjili-lucky-ball",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/LuckyBall.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Lucky Ball",
      name: "Lucky Ball",
    },
    LuckyComing: {
      href: "/casino/qtechjili-lucky-coming",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/LuckyComing.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Lucky Coming",
      name: "Lucky Coming",
    },
    MagicLamp: {
      href: "/casino/qtechjili-magic-lamp",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/MagicLamp.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Magic Lamp",
      name: "Magic Lamp",
    },
    MasterTiger: {
      href: "/casino/qtechjili-master-tiger",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/MasterTiger.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Master Tiger",
      name: "Master Tiger",
    },
    MayanEmpire: {
      href: "/casino/qtechjili-mayan-empire",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/MayanEmpire.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Mayan Empire",
      name: "Mayan Empire",
    },
    MegaAce: {
      href: "/casino/qtechjili-mega-ace",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/MegaAce.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Mega Ace",
      name: "Mega Ace",
    },
    Medusa: {
      href: "/casino/qtechjili-medusa",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Medusa.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Medusa",
      name: "Medusa",
    },
    // MegaFishing: {
    //   href: "/casino/aggapjiligaming-mega-fishing",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/MegaFishing.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Mega Fishing",
    //   name: "Mega Fishing",
    // },
    MoneyComing: {
      href: "/casino/qtechjili-money-coming",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/MoneyComing.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Money Coming",
      name: "Money Coming",
    },
    MoneyComingExpandedBets: {
      href: "/casino/qtechjili-money-coming-expanded-bets",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/MoneyComingExpandedBets.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Money Coming Expanded Bets",
      name: "Money Coming Expanded Bets",
    },
    MonkeyParty: {
      href: "/casino/qtechjili-monkey-party",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/MonkeyParty.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Monkey Party",
      name: "Monkey Party",
    },
    NekoFortune: {
      href: "/casino/qtechjili-neko-fortune",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/NekoFortune.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Neko Fortune",
      name: "Neko Fortune",
    },
    NightCity: {
      href: "/casino/qtechjili-night-city",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/NightCity.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Night City",
      name: "Night City",
    },
    PartyNight: {
      href: "/casino/qtechjili-party-night",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/PartyNight.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Party Night",
      name: "Party Night",
    },
    PartyStar: {
      href: "/casino/qtechjili-party-star",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/PartyStar.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Party Star",
      name: "Party Star",
    },
    PharaohTreasure: {
      href: "/casino/qtechjili-pharaoh-treasure",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/PharaohTreasure.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Pharaoh Treasure",
      name: "Pharaoh Treasure",
    },
    PirateQueen: {
      href: "/casino/qtechjili-pirate-queen",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/PirateQueen.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Pirate Queen",
      name: "Pirate Queen",
    },
    Poseidon: {
      href: "/casino/qtechjili-poseidon",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Poseidon.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Poseidon",
      name: "Poseidon",
    },
    PotionWizard: {
      href: "/casino/qtechjili-potion-wizard",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/PotionWizard.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Potion Wizard",
      name: "Potion Wizard",
    },
    RomaX: {
      href: "/casino/qtechjili-roma-x",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/RomaX.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "RomaX",
      name: "RomaX",
    },
    // RoyalFishing: {
    //   href: "/casino/aggapjiligaming-royal-fishing",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/RoyalFishing.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "Royal Fishing",
    //   name: "Royal Fishing",
    // },
    Samba: {
      href: "/casino/qtechjili-samba",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Samba.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Samba",
      name: "Samba",
    },
    SecretTreasure: {
      href: "/casino/qtechjili-secret-treasure",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/SecretTreasure.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Secret Treasure",
      name: "Secret Treasure",
    },
    // SevenSevenSeven: {
    //   href: "/casino/aggapjiligaming-sevensevenseven",
    //   code: "",
    //   casino: "JiLi",
    //   provider: "ALT_GAMING_GAP",
    //   imgUrl: "./images/games/jili/SevenSevenSeven.jpg",
    //   homeUrl: pageUrlGenerator("/slots"),
    //   alt: "SevenSevenSeven",
    //   name: "SevenSevenSeven",
    // },
    ShanghaiBeauty: {
      href: "/casino/qtechjili-shanghai-beauty",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/ShanghaiBeauty.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Shanghai Beauty",
      name: "Shanghai Beauty",
    },
    SinCity: {
      href: "/casino/qtechjili-sin-city",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/SinCity.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Sin City",
      name: "Sin City",
    },
    SuperRich: {
      href: "/casino/qtechjili-super-rich",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/SuperRich.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Super Rich",
      name: "Super Rich",
    },
    SuperAce: {
      href: "/casino/qtechjili-super-ace",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/SuperAce1.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Super Ace",
      name: "Super Ace",
    },
    SuperAceDeluxe: {
      href: "/casino/qtechjili-super-ace-deluxe",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/SuperAceDeluxe.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Super Ace Deluxe",
      name: "Super Ace Deluxe",
    },
    SweetLand: {
      href: "/casino/qtechjili-sweet-land",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/SweetLand.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Sweet Land",
      name: "Sweet Land",
    },
    ThorX: {
      href: "/casino/qtechjili-thor-x",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/ThorX.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Thor X",
      name: "Thor X",
    },
    TrialofPhoenix: {
      href: "/casino/qtechjili-trial-of-phoenix",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/TrialofPhoenix.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Trial of Phoenix",
      name: "Trial of Phoenix",
    },
    TWINWINS: {
      href: "/casino/qtechjili-twin-wins",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/TWINWINS.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "TWIN WINS",
      name: "TWIN WINS",
    },
    WarOfDragons: {
      href: "/casino/qtechjili-war-of-dragons",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/WarOfDragons.jpg",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "War Of Dragons",
      name: "War Of Dragons",
    },
    WildAce: {
      href: "/casino/qtechjili-wild-ace",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/WildAce.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Wild Ace",
      name: "Wild Ace",
    },
    WildRacer: {
      href: "/casino/qtechjili-wild-racer",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/WildRacer.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Wild Racer",
      name: "Wild Racer",
    },
    WitchesNight: {
      href: "/casino/qtechjili-witches-night",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/WitchesNight.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Witches Night",
      name: "Witches Night",
    },
    WorldCup: {
      href: "/casino/qtechjili-world-cup",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/WorldCup.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "World Cup",
      name: "World Cup",
    },
    XiYangYang: {
      href: "/casino/qtechjili-xiyangyang",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/XiYangYang.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "XiYangYang",
      name: "XiYangYang",
    },
    Zeus: {
      href: "/casino/qtechjili-zeus",
      code: "",
      casino: "JiLi",
      provider: "QTECH",
      imgUrl: "./images/games/jili/Zeus.png",
      homeUrl: pageUrlGenerator("/slots"),
      alt: "Zeus",
      name: "Zenu",
    },
  },
};

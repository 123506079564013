import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Link } from "react-router-dom";
import { Offcanvas, Dropdown, Button } from "react-bootstrap";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import numberWithCommas from "../utils/numbersWithComma";

import MenuIcon from "../assets/images/setting-icon.svg";
import LogoutIconImg from "../assets/images/logout.png";
import MenuIconNew from "../assets/images/menu-button.png";
import UserIcon from "../assets/images/user-icon.png";
import WalletIcon from "../assets/svg/WalletIcon";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import logo from "../assets/images/logo.webp";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";

const HeaderAfterLogin = () => {
  const { user, balance, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(!show1);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDateTime = currentDateTime.toLocaleString("en-US", options);

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (show1) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
  }, [show1]);

  // console.log("balance", wallet);
  return (
    <>
      <header className="header afterlogin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                >
                  <img src={MenuIconNew} alt="User Menu Icon" width={25} />
                </Button>

                <div className="logo">
                  <a href="/sports">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                    {/* <img src={logo} alt="Logo" /> */}
                  </a>
                  <div className="dateTime">
                    <span>{formattedDateTime}</span>
                    {/* <span class="country-standred-time">(+05:30)</span> */}
                  </div>
                </div>

                <GamesSearchModal />

                <div className="headerRight ms-auto">
                  <ul>
                    <a href="/gatewaylist" className="WithDrawHeader">
                      <span className="d-none d-sm-block">Deposit</span>
                      <span className="d-sm-none">D</span>
                    </a>
                    <a href="/withdraw" className="WithDrawHeader withdraw">
                      Withdraw
                    </a>

                    <li className="balance">
                      <div className="balance-amount" href="/cashier">
                        <span className="d-sm-none">
                          Bal: {wallet?.balance}
                        </span>
                        <span className="d-none d-sm-block">
                          Balance: {wallet?.balance}
                        </span>
                        <span className="d-md-block d-none">
                          Bonus: {numberWithCommas(wallet?.coins)}
                        </span>
                      </div>
                    </li>
                  </ul>

                  <Dropdown className="HomeDropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={MenuIcon} alt="User Menu Icon" width={25} />
                      <span>Account</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/profile">
                        <div className="d-flex">
                          <div className="profile-img">
                            <img src={UserIcon} alt="User Icon" />
                          </div>
                          <div className="user-info">
                            <Link className="user_name" to="/profile">
                              {user?.mstruserid}
                            </Link>
                            <p>{user?.mobileno}</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="javascript:void(0)">
                        <div className=" upperbalnace">
                          <div className="balance">
                            <div className="">
                              <WalletIcon />
                              Balance
                            </div>
                            <div className="balance-amount ">
                              BDT {wallet?.balance}
                            </div>
                          </div>
                          <div className="balance">
                            <div
                              className=""
                              onClick={() => {
                                navigate("/withdraw");
                              }}
                            >
                              <Button variant="" className="btn_withdraw w-100">
                                Withdraw
                              </Button>
                            </div>
                            <div
                              className=""
                              onClick={() => {
                                navigate("/gatewaylist");
                              }}
                            >
                              <Button
                                variant="primary"
                                className="btn_primary w-100"
                              >
                                Deposit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="/mybets">My Bets</Dropdown.Item>
                      <Dropdown.Item href="/bonus">Bonus</Dropdown.Item>
                      <Dropdown.Item href="/referral">Referral</Dropdown.Item>
                      <Dropdown.Item href="/cashier">
                        Account Statement
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-md-none d-block"
                        href="#"
                        onClick={() => dispatch(logoutUser())}
                      >
                        Sign Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <ul className="LogoutUSer d-md-block d-none">
                    <li onClick={() => dispatch(logoutUser())}>
                      <a href="#">
                        <img src={LogoutIconImg} />
                        <span>Sign Out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="btmHead">
          <ul>
            <li>
              <a href="/sportsbook/Inplay">Exchange</a>
            </li>
            <li>
              <a href="/betby">Sportsbook</a>
            </li>
            <li>
              <a href="/sportsbook/Cricket">Cricket</a>
            </li>
            <li>
              <a href="/sportsbook/Soccer">Football</a>
            </li>
            <li>
              <a href="/sportsbook/Tennis">Tennis</a>
            </li>
            <li>
              <a href="/casino">Live Casino</a>
            </li>
            <li>
              <a href="/slots">Slots Games</a>
            </li>
            <li>
              <a href="/jilli">Jilli Games</a>
            </li>
            <li>
              <a href="/kingmaker">Kingmaker Games</a>
            </li>
            <li>
              <a href="/casino/aesexy">AeSexy Games</a>
            </li>
            <li>
              <a href="/atlas-lobby">Sportsbook2</a>
            </li>
          </ul>
        </div>

        <Offcanvas
          className="user_section"
          show={show}
          onHide={handleClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={UserIcon} alt="User Icon" />
                </div>
                <div className="user-info">
                  <Link className="user_name" to="/profile">
                    {user?.mstruserid}
                  </Link>
                  <p>{user?.mobileno}</p>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container upperbalnace">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <WalletIcon />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    BDT {wallet?.balance}
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="non_cash_balance">
                    <h6 className="non_cash_balance_heading">
                      <span>
                        <strong> Bonus</strong> <small>Non-Cash-Balance </small>{" "}
                      </span>
                      <span>
                        <strong>{wallet?.coins}</strong>
                      </span>
                    </h6>
                  </div>
                </div> */}
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/gatewaylist");
                  }}
                >
                  <Button variant="primary" className="btn_primary w-100">
                    Deposit
                  </Button>
                </div>
              </div>
            </div>
            <ul className="menu-items">
              {/* <li>
                <a href="/profile">
                  <FaRegUserCircle />
                  <span>Profile</span>
                </a>
              </li> */}
              <li>
                <a href="/mybets">
                  <img src={BetIcon} alt="my bet" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus" className="img_size">
                  <img src={BonusImg} />
                  <span className="bonus-space">Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral" className="img_size">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={AcStatement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>

              <li onClick={() => dispatch(logoutUser())}>
                <a href="#">
                  {/* <LogoutIcon /> */}
                  <img src={LogoutIconImg} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>

            <div className="theme-btn">
              <buton type="button" className="btn" onClick={toggleClass}>
                <img src={MoonLight} alt="moon" className="dark_theme" />
                <img src={SunLight} alt="sun" className="light_theme" />
              </buton>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </header>

      {show1 && <MobLeftbar className="d-md-none" />}
    </>
  );
};

export default HeaderAfterLogin;
